<template>
  <v-card flat class="d-flex align-center justify-center" height="650">
    <v-row no-gutters class="fill-height">

      <v-col cols="3" align="center" align-self="center">
        <v-card flat width="70%" height="275" color="primary" elevation="5" class="d-flex align-center justify-center"
          href="https://casesearch.epcounty.com/PublicAccess/Search.aspx?ID=100">
          <v-card-text class="justify-center white--text text-h5 font-weight-bold">Criminal Case
            Record</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" align="center" align-self="center">
        <v-card flat width="70%" height="275" color="primary" elevation="5" class="d-flex align-center justify-center"
          href="https://casesearch.epcounty.com/PublicAccess/Search.aspx?ID=200">
          <v-card-text class="justify-center white--text text-h5 font-weight-bold">Civil, Family and Probate Case
            Record</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" align="center" align-self="center">
        <v-card flat color="primary" width="70%" height="275" elevation="5" class="d-flex align-center justify-center"
          href="https://casesearch.epcounty.com/PublicAccess/JailingSearch.aspx?ID=500">
          <v-card-text class="justify-center white--text text-h5 font-weight-bold">Jail Bond Records</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" align="center" align-self="center">
        <v-card flat width="70%" height="275" color="primary" elevation="5" class="d-flex align-center justify-center"
          href="https://casesearch.epcounty.com/PublicAccess/JailingSearch.aspx?ID=400">
          <v-card-text class="justify-center white--text text-h5 font-weight-bold">Jail Records</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import moment from "moment";
import QrcodeVue from 'qrcode.vue';
import axios from "axios";
export default {
  name: "todayshearing",
  data() {
    return {
      caseNumber: "",
      size: 200,
      expand: true
    };
  },
  components: {
    SimpleKeyboard,
    QrcodeVue
  },
  methods: {
    onChange(input) {
      this.caseNumber = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.caseNumber = this.caseNumber.slice(0, -1);
    },
    searchByNumber() {
      // implement code here
      // call the API here
      const qs = require('qs');
      let data = qs.stringify({
        'siteKey': 'TXELPASOPROD2017',
        'odysseyMessageXML': '<Message MessageType="FindCaseByCaseNumber" NodeID="1"  ReferenceNumber="ARS-1" UserID="1" Source="Tyler">  <CaseNumber>20230D02275</CaseNumber></Message>'
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://odyintegration/webservices/apiwebservice.asmx/OdysseyMsgExecution',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });



      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(xmlOutputString, 'text/xml');

      // const nodeID = xmlDoc.querySelector('NodeID').textContent;
      // const caseID = xmlDoc.querySelector('CaseID').textContent;
      // const caseNumber = xmlDoc.querySelector('CaseNumber').textContent;
      // const caseStyle = xmlDoc.querySelector('CaseStyle').textContent;
      // const caseStatus = xmlDoc.querySelector('CaseStatus').textContent;
      // const caseType = xmlDoc.querySelector('CaseType').textContent;
      // const caseSecurityGroup = xmlDoc.querySelector('CaseSecurityGroup').textContent;

      // console.log('NodeID:', nodeID);
      // console.log('CaseID:', caseID);
      // console.log('CaseNumber:', caseNumber);
      // console.log('CaseStyle:', caseStyle);
      // console.log('CaseStatus:', caseStatus);
      // console.log('CaseType:', caseType);
      // console.log('CaseSecurityGroup:', caseSecurityGroup);
    },
  },
  mounted() {
    //
  },
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
      "searchcaseno": "Search by case number",
      "searchcasename": "Search by name",
      "firstnamelabel": "First Name",
      "middlenamelabel": "Middle Name",
      "lastnamelabel": "Last Name",
      "casenolabel": "Case Number",
      "casenobutton": "Case Number Search",
      "searchnamebutton": "Search by Name",
      "casenoplaceholder": "Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx"
    },
  "es":{
      "searchcaseno": "Buscar por número de caso",
      "searchcasename": "Buscar por nombre",
      "firstnamelabel": "Primer nombre",
      "middlenamelabel": "Segundo nombre",
      "lastnamelabel": "Apellido",
      "casenolabel": "Número de caso",
      "casenobutton": "Número de caso",
      "searchnamebutton": "Buscar por nombre",
      "casenoplaceholder": "Toque para ingresar el número de caso. P.ej. D-xx-xxx-xxxx-xxxxx"
    }
}
</i18n>
