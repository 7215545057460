
module.exports = {
  firebase: {
    apiKey: "AIzaSyC_khaooIHNMPSVCL_BzjhAnQJ7ei9zL7Q",
    authDomain: "kcmo-45d8e.firebaseapp.com",
    projectId: "kcmo-45d8e",
    storageBucket: "kcmo-45d8e.appspot.com",
    messagingSenderId: "771209630225",
    appId: "1:771209630225:web:170461bc463d29a34957b0"
  }
}

