import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $lang: String) {
  homepages (where : { alias : $alias }, locale: $lang) {
    id
    speech    
    pageName
    locale 
    alias    
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        textList      
      }
    }
    displayIcon{
      id
      url
    }
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String, $category: String) {
  faqCategories(locale: $lang, where : { categoryName : $category}) {
    id
    categoryName
    displayType
    locale
  }
}
`

export const faqQuery = gql`
query faqQuery($lang: String) {
  faqs (start: 0, locale: $lang) {
    id
    category
    locale
    showMap
    mapName
    showQr
    qrDetails
    linkID
    question
    answer
  }
}
`

export const faqQuery2 = gql`
query faqQuery($lang: String) {
  faqs (start: 100, locale: $lang) {
    id
    category
    locale
    mapName
    showQr
    qrDetails
    linkID
    question
    answer
  }
}
`
//---------------------------- Map Page Queries ---------------------------------

export const floorQuery = gql`
query floorQuery($lang: String, $location: String) {
  floors (sort : "floorNumber:ASC", locale: $lang, where : { kioskLocationCode_contains: $location }){ 
    id
    floorName
    floorNumber
    speech
    defaultLocation
    locale
    floorImage {
      id
      url
    }
  }
}
`

export const mapQuery = gql`
query mapQuery($lang: String,  $location: String) {
  maps(sort: "mapFloor:ASC", locale: $lang, where : { kioskLocationCode_contains: $location }){
    id
    mapName
    mapFloor
    speech
    locale
    linkId
    mapImage {
      id
      url
    }
  }
}
`

