import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// The getters are to be called in the way below only when Map Datastructure is used.
let getHomepageEn = () => {
  return store.getters.getHomepage.filter(page => page.locale === "en")
}

let getHomepageEs = () => {
  return store.getters.getHomepage.filter(page => page.locale === "es-US")
}

// Getters for Faq Category -> English Language
let getFaqCategoriesEn = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "en")
}

let getFaqCategoriesEs = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "es-US")
}

let getFaqsEn = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "en")
}

let getFaqsEs = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "es-US")
}

let getFloorsEn = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "en")
}

let getFloorsEs = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "es-US")
}

// Getters for Maps -> English Language
let getMapsEn = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "en") {
      temp.push(map)
    }
  })
  return temp;
}

let getMapsEs = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "es-US") {
      temp.push(map)
    }
  })
  return temp;
}


let getFormCategoriesEn = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "en")
}

let getFormCategoriesEs = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "es-US")
}

let getQnaEn = () => {
  return store.getters.getQnaEndpointEN
}
let getQnaEs = () => {
  return store.getters.getQnaEndpointES
}

let getFormsEn = () => {
  let temp = []
  store.getters.getForms.forEach(form => {
    if (form.locale === 'en') {
      temp.push(form)
    }
  })
  return temp
}

let getFormsEs = () => {
  let temp = []
  store.getters.getForms.forEach(form => {
    if (form.locale === 'es-US') {
      temp.push(form)
    }
  })
  return temp
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Homepage
      homepages: getHomepageEn,

      // Faqs
      faqs: getFaqsEn,
      faqCategory: getFaqCategoriesEn,

      // Maps
      maps: getMapsEn,
      floors: getFloorsEn,

      // Forms
      formCategory: getFormCategoriesEn,
      forms: getFormsEn,

      qnaEndpoint: getQnaEn,
      currentLang: 'en',
      kbError: "Sorry I'm still learning. Please ask me a different question or, get information about this topic at the Violation's Bureau located here.",
      defaultKBError: "Sorry, I'm not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Violation's Bureau located here.",
      mapNotFound: "Sorry, I can't find the requested location, please go to the Violation's Bureau for more information.",
      formNotFound: "Sorry, I can't find the requested form, please go to the Violation's Bureau for more information.",
      selectOptionMsg: "Tap on the below, or click on the microphone to ask another question."
    },
    es: {
      //Homepage
      homepages: getHomepageEs,
      // Faqs
      faqs: getFaqsEs,
      faqCategory: getFaqCategoriesEs,

      // Maps
      maps: getMapsEs,
      floors: getFloorsEs,

      // Forms
      formCategory: getFormCategoriesEs,
      forms: getFormsEn,

      qnaEndpoint: getQnaEs,
      currentLang: 'es-US',
      kbError: "Lo siento, todavía estoy aprendiendo. Hágame una pregunta diferente u obtenga información sobre este tema en la Biblioteca de derecho que se encuentra aquí.",
      defaultKBError: "Lo siento, no estoy seguro de cómo responder a esta pregunta. Hágame otra pregunta o puede continuar usando la interfaz táctil. Además, puede obtener más información en la Biblioteca de Derecho ubicada aquí.",
      mapNotFound: "Lo siento, no puedo encontrar la ubicación solicitada, vaya a la Biblioteca de Derecho para obtener más información.",
      formNotFound: "Lo siento, no puedo encontrar el formulario solicitado, vaya a la Biblioteca de Derecho para obtener más información.",
      selectOptionMsg: "Toque a continuación o haga clic en el micrófono para hacer otra pregunta."
    }
  }
})
